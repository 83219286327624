import "isomorphic-fetch";

import { CREATE as mode } from "Constants/crud";
import { CURRENT_PROPERTY } from "Constants/actionTypes";
import { BAD_REQUEST, CONFLICT, getJSON, generateErrorHandler, INTERNAL_SERVER_ERROR, NOT_FOUND, patchJSON, postJSON, NetworkError } from "Utils/Network";
import { API_URL } from "Config";

const handleError = generateErrorHandler("KEYS_ERROR");

// key APIs
export const propertyKeysRestore = async (keyNestId: string) => {
  const json = await getJSON(
    `${API_URL}v3/keys?name=${keyNestId}&assignedToProperty=0`,
  );
  const { data } = json;
  return data?.map((item: {[key: string]: any}) => {
    const { id, attributes } = item;
    const { keyNestId, name, store } = attributes;
    return {
      id,
      keyNestId,
      name,
      store,
    };
  });
};

export const propertyKeyUpdate = async (keyUuid: string, params: any) => {
  const json = await patchJSON(
    `${API_URL}v3/keys/${keyUuid}`,
    { ...params },
    {
      errorCodes: [BAD_REQUEST, INTERNAL_SERVER_ERROR],
    },
  );
  const { data } = json;
  return data;
};

export const propertyKeyInfo = async (keyUuid: string) => {
  const json = await getJSON(
    `${API_URL}v3/keys/${keyUuid}`,
  );
  const { data } = json;
  return data;
};

export function propertyKeyAdd(keyUuid: string, params: any) {
  return async (dispatch: (...args: any) => any) => {
       try {
         dispatch({ mode, type: CURRENT_PROPERTY.PENDING, status: true });
         const json = await patchJSON(
           `${API_URL}v3/keys/${keyUuid}`,
           { ...params, isPrimary: true },
           {
               errorCodes: [BAD_REQUEST, INTERNAL_SERVER_ERROR],
           },
         );
         const { attributes, id } = json.data;
         dispatch({
           mode,
           type: CURRENT_PROPERTY.SUCCESS,
           currentProperty: { keys: { id, attributes } },
         });
       } catch (err: any) {
         // eslint-disable-next-line
         console.warn("propertyKey error", err);
         dispatch(handleError(err));
       }
     };
}

export const propertyKeyCreate = async (propertyID: string, params: any) => {
  const json = await postJSON(
    `${API_URL}v3/properties/${propertyID}/keys`,
    { ...params },
    {
      errorCodes: [BAD_REQUEST, INTERNAL_SERVER_ERROR],
    },
  );
  const { data } = json;
  return data;
};

export const propertyKeysRefresh = async (keyNestId: string) => {
   const response = await fetch(`${API_URL}v3/keys/${keyNestId}/refresh`, {
     credentials: "include",
     mode: "cors",
     method: "POST",
   });
   if (!response.ok) {
     throw new NetworkError(response);
   }
   return response.status;
 };

 export const propertyKeyRemove = async (keyUuid: string) => {
   const response = await fetch(`${API_URL}v3/keys/${keyUuid}/unassign`, {
     credentials: "include",
     mode: "cors",
     method: "PATCH",
   });
   if (!response.ok) {
     throw new NetworkError(response);
   }
   return response.status;
 };

// Key store APIs
export const propertyKeyStore = async (type: string) => {
  const json = await getJSON(
    `${API_URL}v3/key-stores?type=${type}`,
  );
  const { data } = json;
  return data?.map((item: {[key: string]: any}) => {
    const { id, attributes } = item;
    const { address, keyNestId, name } = attributes;
    return {
      address,
      id,
      keyNestId,
      name,
    };
  });
};

export const keyStoreCreate = async (params: any) => {
  const json = await postJSON(
    `${API_URL}v3/key-stores`,
    { ...params },
    {
      errorCodes: [BAD_REQUEST, INTERNAL_SERVER_ERROR],
    },
  );
  const { data } = json;
  return data;
};

export const keyStoreUpdate = async (keyUuid: string, params: any) => {
  const json = await patchJSON(
    `${API_URL}v3/key-stores/${keyUuid}`,
    { ...params },
    {
      errorCodes: [BAD_REQUEST, INTERNAL_SERVER_ERROR, NOT_FOUND],
    },
  );
  const { data } = json;
  return data;
};

export const keyStoreRemove = async (storeId: string) => {
  const response = await fetch(`${API_URL}v3/key-stores/${storeId}`, {
    credentials: "include",
    mode: "cors",
    method: "DELETE",
  });
    if (response.status === CONFLICT) {
      return response.status;
    }
    if (!response.ok) {
      throw new NetworkError(response);
    }
    return true;
};

export const getKeyStoreSignedUrl = async () => {
  const uploadUrl = `${API_URL}v3/key-stores/attachment-url`;

  try {
    const json = await getJSON(uploadUrl);
    const { s3Key, signedUrl } = json.data.attributes;
    return { s3Key, signedUrl };
  } catch (err) {
    // eslint-disable-next-line
    console.warn("getKeyStoreSignedUrl error", err);
    return handleError(err);
  }
};
