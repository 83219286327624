import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { InputText } from "@madecomfy/webooi";

import LocationAutoCompleteNaked, {
  IProps as LocationAutoCompleteProps,
} from "Containers/PropertyDetails/Location/LocationAutoComplete";
import withGoogle from "HOC/withGoogle";
import {
  calculateDistance,
  convertToMeters,
} from "../Elements/DistanceCalculator";
import withGoogleFunction from "HOC/withGoogleFunction";

const LocationAutoComplete = withGoogle<LocationAutoCompleteProps>(
  LocationAutoCompleteNaked,
);
const calculateDistanceNaked = withGoogleFunction(calculateDistance);

const Wrapper = styled.div``;

interface IProps {
  distance?: any;
  keyType?: string;
  setDistance?: any;
  propertyAddressCountry?: string;
  propertyAddressState?: string;
  store?: any;
  onAddressChange: (data: any) => void;
  origin?: string;
}
const AddressText = styled.p`
  text-align: center;
  margin: 24px 0 24px 0 !important;
  span {
    color: ${({ theme }) => theme.text.link};
    cursor: pointer;
  }
`;
const DoubleColumns = styled.div<{ gap?: string; mt?: string; mb?: string }>`
  display: flex;
  margin: 0;
  margin-top: ${({ mt }) => mt || "24px"};
  margin-bottom: ${({ mb }) => mb || "24px"};
  gap: ${({ gap }) => gap || "8px"};
  div {
    flex: 1;
  }
`;

export const Address: React.FC<IProps> = ({
  distance,
  keyType,
  onAddressChange,
  origin,
  propertyAddressCountry,
  propertyAddressState,
  setDistance,
  store,
}) => {
  const [googlePlaceId, setGooglePlaceId] = useState(
    store?.googlePlaceId || null,
  );
  const [latitude, setLatitude] = useState(store?.latitude || null);
  const [longitude, setLongitude] = useState(store?.longitude || null);

  const [manualAddress, setManualAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(false);

  const [storeAddress, setStoreAddress] = useState(store?.address || "");
  const [storeStreetAddress, setStoreStreetAddress] = useState(
    store?.addressComponents?.street || "",
  );
  const [storeCity, setStoreCity] = useState(
    store?.addressComponents?.city || "",
  );
  const [storePostcode, setStorePostcode] = useState(
    store?.addressComponents?.postcode || "",
  );
  const [storeState, setStoreState] = useState(
    store?.addressComponents?.state || propertyAddressState || "",
  );
  const [storeCountry, setStoreCountry] = useState(
    store?.addressComponents?.country || propertyAddressCountry || "",
  );

  const toggleManualAddress = (manual: boolean) => {
    setStoreAddress("");
    setStoreStreetAddress("");
    setStoreCity("");
    setStorePostcode("");
    setLatitude("");
    setLongitude("");
    setGooglePlaceId("");
    setDistance?.("");
    setManualAddress(manual);
    setSelectedAddress(false);

    onAddressChange({
      storeAddress: "",
      storeCity: "",
      storeStreetAddress: "",
      storeCountry: "",
      storeState: "",
      storePostcode: "",
      selectedAddress: false,
      latitude: "",
      longitude: "",
      googlePlaceId: "",
    });
  };

  const prevAddress = useRef<{
    storeAddress: any;
    storeCity: any;
    storeStreetAddress: any;
    storeCountry: any;
    storeState: any;
    storePostcode: any;
    selectedAddress: boolean;
    latitude: any;
    longitude: any;
    googlePlaceId: any;
  } | null>(null);

  useEffect(() => {
    const newAddressData = {
      storeAddress,
      storeCity,
      storeStreetAddress,
      storePostcode,
      selectedAddress,
      latitude,
      longitude,
      googlePlaceId,
      manualAddress,
      storeCountry,
      storeState,
    };

    if (
      JSON.stringify(prevAddress.current) !== JSON.stringify(newAddressData)
    ) {
      onAddressChange(newAddressData);
      prevAddress.current = newAddressData;
    }
  }, [
    storeAddress,
    storeCity,
    storeStreetAddress,
    storePostcode,
    selectedAddress,
    latitude,
    longitude,
    googlePlaceId,
    onAddressChange,
    storeCountry,
    storeState,
  ]);

  return (
    <Wrapper>
      {!manualAddress ? (
        keyType === "key-nest" ? (
          <InputText
            label="Location"
            instructions={"The address where the key is located"}
            name="address"
            placeholder="Enter key location address"
            my={0}
            isEnabled={false}
            value={store?.address || ""}
          />
        ) : (
          <>
            <LocationAutoComplete
              handleLocationSelect={({ address }: any) => {
                const { full: destination } = address;
                setStoreAddress(destination);
                setLatitude(address?.latitude);
                setLongitude(address?.longitude);
                setGooglePlaceId(address?.placeId);
                if (origin) {
                  calculateDistanceNaked(
                    origin,
                    destination,
                    (error, result) => {
                      if (error) {
                        // eslint-disable-next-line
                        console.error("Error:", error);
                        return;
                      }
                      setDistance?.(`${convertToMeters(result)} m`);
                    },
                  );
                }
                setSelectedAddress(true);
                onAddressChange({
                  storeAddress: destination,
                  storeCity,
                  storeStreetAddress,
                  storePostcode,
                  selectedAddress: true,
                  latitude: address?.latitude,
                  longitude: address?.longitude,
                  googlePlaceId: address?.placeId,
                  manualAddress,
                  storeCountry,
                  storeState,
                });
              }}
              initialAddress={store?.address}
              componentRestrictions={{}}
              label="Location"
              instructions="The address where the key is located"
              placeholder="Start typing key location address"
            />
            <AddressText>
              Can&apos;t find the address?{` `}
              <span onClick={() => toggleManualAddress(!manualAddress)}>
                Enter address manually
              </span>
            </AddressText>
          </>
        )
      ) : (
        <>
          <InputText
            label="Street address"
            instructions="The address where the key is located"
            my={24}
            name="streetAddress"
            sendValue={({ value }: any) => setStoreStreetAddress(value)}
            value={storeStreetAddress}
          />
          <InputText
            label="City"
            my={24}
            name="city"
            sendValue={({ value }: any) => setStoreCity(value)}
            value={store?.addressComponents?.city}
          />
          <DoubleColumns>
            <InputText
              label="Postcode"
              my={0}
              name="postcode"
              sendValue={({ value }: any) => setStorePostcode(value)}
              value={storePostcode}
            />
            <InputText
              label="State"
              my={0}
              isEnabled={Boolean(!origin)}
              name="state"
              value={storeState}
              sendValue={({ value }: any) => setStoreState(value)}
            />
          </DoubleColumns>
          <InputText
            label="Country"
            mt={0}
            mb={24}
            name="country"
            isEnabled={Boolean(!origin)}
            value={storeCountry}
            sendValue={({ value }: any) => setStoreCountry(value)}
          />
          <AddressText onClick={() => toggleManualAddress(!manualAddress)}>
            <span>Use address search</span>
          </AddressText>
        </>
      )}
      {(selectedAddress || manualAddress) && (
        <>
          {origin && (
            <InputText
              label="Distance from property in metres"
              mt={0}
              mb={24}
              name="distance"
              isEnabled={manualAddress}
              placeholder="Enter distance from property in metres"
              sendValue={({ value }: any) => setDistance(value)}
              value={distance}
            />
          )}
          <DoubleColumns>
            <InputText
              label="Latitude"
              my={0}
              name="latitude"
              isEnabled={manualAddress}
              placeholder="Latitude"
              sendValue={({ value }: any) => setLatitude(value)}
              value={latitude}
            />
            <InputText
              label="Longitude"
              my={0}
              name="longitude"
              isEnabled={manualAddress}
              placeholder="Longitude"
              sendValue={({ value }: any) => setLongitude(value)}
              value={longitude}
            />
          </DoubleColumns>
          <InputText
            label={`Google Place ID${manualAddress ? " - Optional" : ""}`}
            instructions={
              manualAddress
                ? "Go to https://comf.co/placeid to use the Place ID Finder"
                : null
            }
            mt={0}
            mb={24}
            name="googlePlaceId"
            isEnabled={manualAddress}
            placeholder="Google Place ID"
            sendValue={({ value }: any) => setGooglePlaceId(value)}
            value={googlePlaceId}
          />
        </>
      )}
    </Wrapper>
  );
};
