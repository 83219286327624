import React from "react";

const Arrow = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8536 5.56644C10.6583 5.76171 10.3417 5.76171 10.1464 5.56644L8.5 3.92L8.5 14.7129C8.5 14.989 8.27614 15.2129 8 15.2129C7.72386 15.2129 7.5 14.989 7.5 14.7129L7.5 3.92L5.85355 5.56644C5.65829 5.76171 5.34171 5.76171 5.14645 5.56644C4.95118 5.37118 4.95118 5.0546 5.14645 4.85934L7.64645 2.35934C7.69438 2.3114 7.74964 2.27523 7.80861 2.25083C7.86756 2.22638 7.93221 2.21289 8 2.21289C8.06779 2.21289 8.13244 2.22638 8.19139 2.25083C8.25036 2.27523 8.30562 2.3114 8.35355 2.35934L10.8536 4.85934C11.0488 5.0546 11.0488 5.37118 10.8536 5.56644Z"
    />
  </>
);

export default Arrow;
