import React, { useState } from "react";
import styled from "styled-components";

import { BUTTON_SECONDARY, BUTTON_DANGER } from "@madecomfy/webooi";

import Popup from "Components/Popup";
import Generic from "Components/Popup/GenericWrapper";
import { keyStoreRemove } from "Actions/properties/propertyKeys";

const Wrapper = styled.div`
  p {
    font-size: 16px;
  }
`;

interface IProps {
  storeId: string;
  templatesRestore: Function;
  setShowDeletePopup: Function;
}

const TemplateDeletePopup: React.FC<IProps> = ({
  setShowDeletePopup,
  storeId,
  templatesRestore,
}) => {
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setAlert("");
    setLoading(true);
    const res = await keyStoreRemove(storeId);
    if (res === 409) {
      setLoading(false);
      setAlert(
        "Property Key is using this Key Collection Template, cannot delete it.",
      );
      return;
    }
    const params = {
      query: "",
      number: 1,
      sort: "name",
    };
    templatesRestore(params);
    setTimeout(() => setShowDeletePopup(null), 200);
  };

  return (
    <Popup
      body={
        <Generic
          alert={alert}
          body={
            <Wrapper>
              <p>
                Are you sure you want to delete this key collection template?
              </p>
            </Wrapper>
          }
          buttons={[
            {
              label: "Cancel",
              onClick: () => setShowDeletePopup(null),
              type: BUTTON_SECONDARY,
            },
            { label: "Delete", onClick: handleDelete, type: BUTTON_DANGER },
          ]}
          loading={loading}
          title="Delete key collection template"
        />
      }
      handleClose={() => setShowDeletePopup(null)}
      width={450}
    />
  );
};

export default TemplateDeletePopup;
