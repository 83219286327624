import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import React, { useState } from "react";
import styled from "styled-components";

import { InputTitleLabel, Instructions } from "@madecomfy/webooi";

import theme from "Constants/theme";
import { InputText } from "Components/InputTextUpdate/InputTextUpdate";
import { parsedGooglePlacesAddress } from "Utils";

const AutoComplete = styled.div`
  .autocomplete-dropdown-container {
    position: absolute;
    width: 93%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    z-index: 4;
    div {
      padding: 8px 16px;
    }
  }
`;
export interface IProps {
  componentRestrictions?: { [key: string]: any };
  handleLocationSelect?: any;
  initialAddress?: string;
  instructions?: string;
  isEnabled?: boolean;
  label?: string;
  placeholder?: string;
}
const LocationAutoComplete: React.FC<IProps> = (props) => {
  const {
    componentRestrictions = { country: ["au", "nz"] },
    handleLocationSelect,
    initialAddress,
    instructions,
    isEnabled = true,
    label,
    placeholder = "Enter address",
  } = props;
  const [isValid, setValid] = useState(Boolean(initialAddress)); // there is not much we can do to validate if the initial address is valid.
  const [address, setAddress] = useState<any>(initialAddress);
  const [lastValidAddress, setLastValidAddress] = useState(initialAddress);

  const handleChange = (address: any) => {
    setAddress(address);
  };

  const handleBlur = () => {
    if (isValid) {
      setAddress(lastValidAddress);
    } else {
      setAddress(null);
    }
  };

  const handleSelect = async (address: any) => {
    try {
      const results = await geocodeByAddress(address);
      const newAddress = parsedGooglePlacesAddress(results[0]);
      setAddress(newAddress?.full);
      setLastValidAddress(newAddress?.full);
      setValid(true);
      handleLocationSelect({ address: newAddress, isValid });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error", error);
    }
  };

  return (
    <PlacesAutocomplete
      value={address || ""}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{ componentRestrictions, types: ["address"] } as any}
    >
      {(autoProps: { [key: string]: any }) => {
        const { getInputProps, suggestions, getSuggestionItemProps, loading } =
          autoProps;
        return (
          <AutoComplete>
            {label && <InputTitleLabel>{label}</InputTitleLabel>}
            {instructions && <Instructions>{instructions}</Instructions>}
            <InputText
              {...getInputProps({ onBlur: handleBlur })}
              isEnabled={isEnabled}
              placeholder={placeholder}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(
                (suggestion: { [key: string]: any }, index: number) => {
                  const style = suggestion.active
                    ? { backgroundColor: theme.sand98, cursor: "pointer" }
                    : { backgroundColor: theme.white, cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={index}
                    >
                      {suggestion.description}
                    </div>
                  );
                },
              )}
            </div>
          </AutoComplete>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default LocationAutoComplete;
