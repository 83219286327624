import React, { Fragment, Component } from "react";
import RUG, { List, DragArea } from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";

import { ALERT_ERROR, ALERT_INFO, Alert } from "@madecomfy/webooi";

import { Body } from "Components/Typography";
import {
  getInstructionsSignedUrl,
  getManualsSignedUrl,
  photoCreate,
} from "Actions/properties/propertyPhotos";
import { getKeyStoreSignedUrl } from "Actions/properties/propertyKeys";
import { ListWrap, UploadCard, WrapperUpload } from "./styles";
import { IMG_BASE_URL } from "Constants/misc";

class UploadGallery extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      photos: this.props?.data?.map((item: { [key: string]: any } = {}) => {
        const { s3Key: originalUrl } = item;
        return {
          originalUrl,
          source: `${IMG_BASE_URL}/350x/secure-assets/${originalUrl}`,
        };
      }),
      photoSaveError: [],
      photoUploadError: "",
      initialState: true,
      photosCount: [],
    };
  }
  render() {
    const { category, testId, title } = this.props;
    const { photos, photoSaveError, initialState, photoUploadError } =
      this.state;
    return (
      <>
        {category !== "check-out" && (
          <>
            <Body data-test={testId}>{title}</Body>
            <WrapperUpload>
              <RUG
                accept={["jpg", "jpeg", "png"]}
                customRequest={this.customRequest}
                initialState={photos}
                onChange={(images: any[]) => {
                  images.forEach((image) => {
                    const photo = photos.find(
                      (photo: { [key: string]: any }) =>
                        photo.uid === image.uid,
                    );
                    if (photo) {
                      image.source = photo.source;
                      image.originalUrl = photo.originalUrl;
                    }
                  });
                  this.setState({ photos: images });
                  this.props.onChildValueChange(images);
                }}
                onConfirmDelete={() => {
                  return window.confirm("Are you sure you want to delete?");
                }}
              >
                <Fragment>
                  <Alert
                    dismissible
                    message="Drag and drop the photos to rearrange the order they're displayed in the Guest Portal. To change the order of the photos, press and hold the image until it reduces in size, then drag to the required position."
                    type={ALERT_INFO}
                    my={16}
                  />
                  {photoUploadError && (
                    <Alert
                      message={photoUploadError}
                      testId="photo-upload-error"
                      type={ALERT_ERROR}
                    />
                  )}
                  {!initialState &&
                    photoSaveError &&
                    photoSaveError.length > 0 && (
                      <Alert
                        message={
                          <ul>
                            {photoSaveError.map((error: any, i: React.Key) => {
                              return <li key={i}>{error}</li>;
                            })}
                          </ul>
                        }
                        type={ALERT_ERROR}
                      />
                    )}
                  <UploadCard data-test="list-view" view="list">
                    <DragArea>
                      {(image: { [key: string]: any }) => (
                        <ListWrap>
                          <List image={image} />
                        </ListWrap>
                      )}
                    </DragArea>
                  </UploadCard>
                </Fragment>
              </RUG>
            </WrapperUpload>
          </>
        )}
      </>
    );
  }
  customRequest = async ({ uid, file }: any) => {
    const { propertyId, onChildValueChange, type } = this.props;
    const { photosCount } = this.state;
    const actions: Record<string, (id: string) => Promise<any>> = {
      instructions: getInstructionsSignedUrl,
      manuals: getManualsSignedUrl,
      template: getKeyStoreSignedUrl,
    };
    const result = actions[type] ? await actions[type](propertyId) : undefined;

    if (result?.errorCode) {
      this.setState({
        photoUploadError: `${result?.errorCode} Could not generate an endpoint to save photo`,
      });
      return;
    }
    const signedUrl = result?.signedUrl;
    const s3Key = result?.s3Key;
    photosCount.push(signedUrl);
    this.setState({ uploading: true }, async () => {
      const photoResult = (await photoCreate(signedUrl, file)) as any;
      if (photoResult?.errorCode) {
        this.setState({
          photoUploadError: `${photoResult?.errorCode} Could not save photo`,
        });
        return;
      }
      photosCount.pop(signedUrl);
      const res = this.state.photos.find(
        (photo: { [key: string]: any }) => photo.uid === uid,
      );
      if (res) {
        res.uploading = false;
      }
      if (photosCount.length === 0) {
        this.setState({ uploading: false });
      }
    });

    const photosArr = [...this.state.photos];

    photosArr.forEach((photo) => {
      if (photo.uid === uid) {
        photo.source = URL.createObjectURL(file);
        photo.originalUrl = s3Key;
      }
    });
    this.setState({ photos: photosArr });
    onChildValueChange(photosArr);
  };
}
export default UploadGallery;
