import React from "react";
import styled, { css } from "styled-components";

import {
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  Form,
  ValidatedInputText,
  ValidatedSelect,
} from "@madecomfy/webooi";

import Generic from "Components/Popup/GenericWrapper";
import Popup from "Components/Popup";
import TextEditor from "Components/TextEditor/TextEditor";
import UploadGallery from "Components/UploadGallery/UploadGallery";
import { Address } from "../../Containers/PropertyAccessCodes/Offsite/Address";
import {
  keyStoreCreate,
  keyStoreUpdate,
} from "Actions/properties/propertyKeys";
import { notEmpty } from "Utils";

const PopupWrapper = styled.div`
  textarea {
    min-height: 150px;
  }
  label {
    margin-bottom: 0;
  }
  input[role="combobox"] {
    margin-bottom: 0;
  }
`;
const WrapperInput = styled.div<{ isEnabled: boolean }>`
  input,
  select,
  textarea {
    ${({ isEnabled }) =>
      !isEnabled &&
      css`
        background: #e0dfdf;
        cursor: not-allowed;
        pointer-events: none;
      `};
  }
`;
class TemplatePopup extends Form<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      address: "",
      description: this.props?.storeData?.data?.longDescription,
      getPhotos: null,
      isEnabled: false,
      keyType: this.props?.storeData?.data?.storeType,
      name: "",
      updatedAddress: null,
    };
  }
  componentDidMount() {
    this.setState({
      address: this.props.storeData?.data?.address || "",
      description: this.props.storeData?.data?.longDescription || "",
    });
    this.updateIsEnabled();
  }
  handleChildValue = (value: any) => {
    this.setState({ getPhotos: value });
  };
  handleAddressChange = (updatedAddress: any) => {
    this.setState({ updatedAddress });
  };

  componentDidUpdate(prevState: any) {
    if (
      prevState.updatedAddress !== this.state.updatedAddress &&
      this.savable() !== this.state.isEnabled
    ) {
      this.updateIsEnabled();
    }
  }

  componentWillUnmount() {
    this.setState({ isEnabled: false });
  }

  savable = () => {
    const { name, updatedAddress } = this.state;
    return (
      name &&
      (!updatedAddress?.manualAddress ||
        (updatedAddress?.storeStreetAddress &&
          updatedAddress?.storeCity &&
          updatedAddress?.storePostcode &&
          updatedAddress?.latitude &&
          updatedAddress?.longitude))
    );
  };

  updateIsEnabled = () => {
    const newIsEnabled = this.savable();
    if (newIsEnabled !== this.state.isEnabled) {
      this.setState({ isEnabled: newIsEnabled });
    }
  };

  render() {
    const { address, description, isEnabled, keyType, ...rest } = this.state;
    const { storeData, setReset, setShowPopup } = this.props;
    const { data } = storeData;
    const isKeyNest = keyType === "key-nest";

    return (
      <Popup
        body={
          <PopupWrapper>
            <Generic
              body={
                <>
                  <WrapperInput isEnabled={!isKeyNest}>
                    <ValidatedSelect
                      {...rest}
                      label="Key Collection Type"
                      instructions="Select the type of key collection"
                      name="storeType"
                      options={[
                        {
                          value: "convenience-store",
                          label: "Convenience Store",
                        },
                        {
                          value: "offsite",
                          label: "Offsite",
                        },
                        isKeyNest && {
                          value: "key-nest",
                          label: "KeyNest",
                        },
                      ].filter(Boolean)}
                      sendValue={({ value }: any) =>
                        this.setState({ keyType: value })
                      }
                      value={keyType}
                    />
                    <ValidatedInputText
                      {...rest}
                      label="Title"
                      instructions="Template name as it should appear in the dashboard"
                      name="name"
                      placeholder="Enter the title of the template"
                      value={data?.name || ""}
                      validation={notEmpty}
                      sendValue={({ value }: any) =>
                        this.setState({ name: value })
                      }
                    />
                  </WrapperInput>
                  <ValidatedInputText
                    {...rest}
                    label="Display name"
                    instructions="This name is displayed to the guest in the Guest Portal"
                    name="displayName"
                    placeholder="Enter the name"
                    value={data?.displayName || ""}
                    validation={notEmpty}
                  />
                  <Address
                    keyType={keyType}
                    onAddressChange={this.handleAddressChange}
                    store={data}
                  />
                  <WrapperInput isEnabled={!isKeyNest}>
                    <ValidatedInputText
                      {...rest}
                      label="Opening hours"
                      name="openingTime"
                      multiline
                      mt={24}
                      mb={24}
                      value={data?.openingTime || ""}
                    />
                  </WrapperInput>
                  <TextEditor
                    onSave={(htmlContent) =>
                      this.setState({ description: htmlContent })
                    }
                    label="Details"
                    instructions="How to find the location"
                    value={description}
                  />
                  <UploadGallery
                    onChildValueChange={this.handleChildValue}
                    data={data?.photos}
                    propertyId={data?.storeId}
                    testId="upload-photos-label"
                    title="Photos"
                    type="template"
                  />
                </>
              }
              buttons={[
                {
                  label: "Cancel",
                  onClick: () => setShowPopup(false),
                  type: BUTTON_SECONDARY,
                },
                {
                  label: "Save",
                  isEnabled: isEnabled,
                  onClick: async () => {
                    const isValid = this.validate();
                    if (!isValid) return;
                    const {
                      name,
                      description,
                      getPhotos,
                      keyType,
                      updatedAddress,
                    } = this.state;
                    this.setState({
                      isEnabled:
                        isValid &&
                        (!updatedAddress?.manualAddress ||
                          (updatedAddress?.storeStreetAddress &&
                            updatedAddress?.storeCity &&
                            updatedAddress?.storePostcode &&
                            updatedAddress?.latitude &&
                            updatedAddress?.longitude)),
                    });
                    if (!isEnabled) return;
                    const payload = this.getValues();
                    const { storeData, templatesRestore } = this.props;
                    const templatePhotos = getPhotos?.map(
                      (item: { [key: string]: any }, i: number) => {
                        return {
                          s3Key: item.originalUrl,
                        };
                      },
                    );
                    const params = {
                      ...payload,
                      longDescription: description,
                      photos: templatePhotos,
                      storeType: keyType,
                      address: updatedAddress?.storeAddress,
                      latitude: parseFloat(updatedAddress?.latitude || ""),
                      longitude: parseFloat(updatedAddress?.longitude || ""),
                      googlePlaceId: updatedAddress?.googlePlaceId,
                    };
                    if (!isKeyNest) {
                      params.name = name;
                    }
                    if (updatedAddress?.manualAddress) {
                      params.addressComponents = {
                        street: updatedAddress?.storeStreetAddress,
                        city: updatedAddress?.storeCity,
                        postcode: updatedAddress?.storePostcode,
                        state: updatedAddress?.storeState,
                        country: updatedAddress?.storeCountry,
                      };
                      delete params.address;
                    }
                    const res =
                      storeData?.type === "edit"
                        ? await keyStoreUpdate(storeData?.data?.storeId, params)
                        : await keyStoreCreate(params);
                    if (res?.id) {
                      setShowPopup(false);
                      const params = {
                        query: "",
                        number: 1,
                        sort: "name",
                      };
                      setReset(true);
                      templatesRestore(params);
                    }
                  },
                  type: BUTTON_PRIMARY,
                },
              ]}
              loading={false}
              title={
                storeData.type === "add"
                  ? "Add a key collection template"
                  : "Edit Key Collection Template"
              }
            />
          </PopupWrapper>
        }
        handleClose={() => setShowPopup(false)}
        width={550}
      />
    );
  }
}

export default TemplatePopup;
