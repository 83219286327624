// High Order Component to make sure google maps API is available
// it is a little bit complex but tries to handle all known cases.

import React, { PureComponent } from "react";

import { Alert, ALERT_ERROR } from "@madecomfy/webooi";

import Loading from "Components/Loading";
import { loadScript } from "Utils";

const scriptUrl =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBo00hBWw-wB17EwD2KdNOjALfkFeSLBfs&language=en&libraries=geometry,drawing,places";
// eslint-disable-next-line
const withGoogle = function <I = { center?: { [key: string]: any } }>(
  WrappedComponent: any,
) {
  return class extends PureComponent<I> {
    state = {
      attempts: 0,
      error: false,
      loaded: Boolean(window.google),
    };

    isComponentMounted = false;

    async componentDidMount() {
      this.isComponentMounted = true;
      if (this.state.loaded) return;

      if (window.google) {
        this.setState({ loaded: true });
        return;
      }

      this.checkGoogle();
      try {
        await loadScript("googlemaps", scriptUrl);
        // script is loaded, but window.google may not be defined yet...
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn("withGoogle error", err);
        this.setState({ error: true });
      }
    }

    componentWillUnmount() {
      this.isComponentMounted = false;
    }

    checkGoogle = async () => {
      if (!this.isComponentMounted) return;
      const { attempts, error } = this.state;
      if (error || attempts > 8) {
        // failed, what can you do?
        this.setState({ error: true });
        return;
      }
      if (window.google) {
        // all good...
        this.setState({ loaded: true });
        return;
      }
      // check again.
      this.setState({ attempts: attempts + 1 }, () => {
        setTimeout(this.checkGoogle, 100 + attempts * attempts * 50); // wait increasingly longer for google to be defined
      });
    };

    render() {
      const { loaded, error } = this.state;
      if (error) {
        return (
          <Alert
            message="Google Maps failed to load"
            title="Map could not initialise"
            type={ALERT_ERROR}
          />
        );
      }
      if (loaded) {
        return <WrappedComponent {...this.props} />;
      }
      return <Loading task="initialisingGoogle" />;
    }
  };
};

export default withGoogle;
