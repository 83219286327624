export const calculateDistance = (
  origin: string,
  destination: string,
  callback: (error: string | null, result: string | null) => void,
): void => {
  if (!window.google || !window.google.maps) {
    // eslint-disable-next-line standard/no-callback-literal
    callback("Google Maps API is not loaded.", null);
    return;
  }

  const service = new window.google.maps.DistanceMatrixService();

  service.getDistanceMatrix(
    {
      origins: [origin],
      destinations: [destination],
      travelMode: window.google.maps.TravelMode.DRIVING,
    },
    (
      // eslint-disable-next-line no-undef
      response: google.maps.DistanceMatrixResponse | null,
      // eslint-disable-next-line no-undef
      status: google.maps.DistanceMatrixStatus,
    ) => {
      if (status === window.google.maps.DistanceMatrixStatus.OK && response) {
        const element = response.rows[0]?.elements[0];
        if (
          element?.status === window.google.maps.DistanceMatrixElementStatus.OK
        ) {
          callback(null, element.distance?.text || "N/A");
          return;
        }
        // eslint-disable-next-line standard/no-callback-literal
        callback(`Error in calculating distance: ${element?.status}`, null);
        return;
      }
      // eslint-disable-next-line standard/no-callback-literal
      callback(`Distance Matrix API error: ${status}`, null);
    },
  );
};

export const convertToMeters = (value: string | null): number | null => {
  if (!value) {
    return null; // Return null for null or undefined input
  }
  if (typeof value === "number") {
    return value;
  }

  const match = value.match(/^([\d,.]+)(\D*)$/);
  if (!match) {
    // eslint-disable-next-line
    console.warn("Invalid distance value, defaulting to 0:", value);
    return 0;
  }

  const sanitizedNumber = match[1].replace(/,/g, ""); // Remove commas
  const distance = parseFloat(sanitizedNumber);
  if (isNaN(distance)) {
    // eslint-disable-next-line
    console.warn("Invalid numeric value, defaulting to 0:", value);
    return 0;
  }

  const unit = match[2].trim().toLowerCase(); // Extract and clean unit

  // Convert km to meters, otherwise assume meters
  return unit === "km" ? distance * 1000 : distance;
};
