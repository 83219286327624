import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Templates from "./Templates";

import { templatesRestore } from "Actions/templates/templatesRestore";

const mapStateToProps = (state: any) => ({
  templates: state.searchTemplates,
});

const mapDispatchToProps = (dispatch: any) => ({
    templatesRestore: bindActionCreators(templatesRestore, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
