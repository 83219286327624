import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Drawer, Radio } from "@madecomfy/webooi";

import { H3 } from "Components/Typography";

const Wrapper = styled.div``;
const WrapperSort = styled.div`
  h3 {
    margin-top: -24px;
    @media only screen and (max-width: 500px) {
      margin-top: -16px;
    }
  }
  div:last-child {
    p {
      display: none;
    }
  }
`;

const WrapperDrawer = styled.div`
  div > div + button {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    align-items: baseline;
    background: ${({ theme }) => theme.background.subtle3};
    color: #303336;
    padding: 8px 32px;
    height: 40px;
    border-radius: 4px;
    :focus {
      text-decoration: none;
      color: #303336;
    }
    svg {
      fill: #303336 !important;
    }
    :hover {
      background: #e9e9ea;
      color: #303336;
    }
  }
  svg:first-child {
    width: 16px;
    height: 16px;
  }
  div[data-test="drawer-content"] {
    > div:first-child {
      width: auto;
    }
  }
`;
const RadioWrapper = styled.div<{
  selectedValue: { name: string; value: boolean };
}>`
  div {
    label:first-child {
      margin-bottom: 16px;
      font-weight: 700;
      line-height: 22px;
    }
  }
  span {
    border: 2px solid ${({ theme }) => theme.icon.subtle};
    :after {
      background: none;
    }
  }
  div[data-test="radio-${({ selectedValue }) => selectedValue?.name}"] {
    input[data-test="radio-option-${({ selectedValue }) =>
        `${selectedValue?.name}-${selectedValue?.value}`}"]
      + span {
      border: 6px solid #2192a3;
    }
    span {
      :after {
        background: none;
      }
    }
  }
`;
const Line = styled.p`
  border-top: 1px solid #efefef;
  margin-top: 24px;
  margin-bottom: 24px;
`;
const LineSpacer = styled(Line)`
  margin: 24px -24px 24px -24px;
`;

export const Sort = ({
  isLicensee,
  isOwner,
  setSort,
  sort,
  isPriceRange,
}: any) => {
  const sortOptions = [
    {
      label: "Property name",
      name: "propertyName",
      options: [
        { value: true as any, label: "A-Z" },
        { value: false as any, label: "Z-A" },
      ],
      testId: "propertyName",
    },
    /* {
      label: "Property address",
      name: "propertyAddress",
      options: [
        { value: true as any, label: "A-Z" },
        { value: false as any, label: "Z-A" },
      ],
      testId: "propertyAddress",
    }, */
    {
      label: "Bedrooms",
      name: "bedrooms",
      options: [
        { value: true as any, label: "Ascending" },
        { value: false as any, label: "Descending" },
      ],
      testId: "bedrooms",
    },
    {
      label: "Bathrooms",
      name: "bathrooms",
      options: [
        { value: true as any, label: "Ascending" },
        { value: false as any, label: "Descending" },
      ],
      testId: "bathrooms",
    },
    {
      label: "No of guests",
      name: "maxGuests",
      options: [
        { value: true as any, label: "Ascending" },
        { value: false as any, label: "Descending" },
      ],
      testId: "maxGuests",
    },
    {
      label: "Min nights",
      name: "minimumStay",
      options: [
        { value: true as any, label: "Ascending" },
        { value: false as any, label: "Descending" },
      ],
      testId: "minimumStay",
    },
    {
      label: "Rating",
      name: "airbnbOverallRating",
      options: [
        { value: true as any, label: "Ascending" },
        { value: false as any, label: "Descending" },
      ],
      testId: "airbnbOverallRating",
    },
    !isOwner && {
      label: "Tasks",
      name: "tasks",
      options: [
        { value: true as any, label: "Ascending" },
        { value: false as any, label: "Descending" },
      ],
      testId: "tasks",
    },
    !isLicensee && {
      label: "Partner",
      name: "companyName",
      options: [
        { value: true as any, label: "A-Z" },
        { value: false as any, label: "Z-A" },
      ],
      testId: "companyName",
    },
    // isPriceRange && {
    //   label: "Price",
    //   name: "price",
    //   options: [
    //     { value: true as any, label: "Ascending" },
    //     { value: false as any, label: "Descending" },
    //   ],
    //   testId: "price",
    // },
  ].filter(Boolean);
  const [selectedValue, setSelectedValue] = useState({
    name: "",
    value: false,
  });
  const [activeSelectedValue, setActiveSelectedValue] = useState({
    name: "",
    value: false,
  });
  const [sortNumber, setSortNumber] = useState(false);
  const [isShowDrawer, setIsShowDrawer] = useState(false);

  useEffect(() => {
    if (isShowDrawer) {
      setSelectedValue(activeSelectedValue);
    }
  }, [isShowDrawer]);

  useEffect(() => {
    const isAscending = sort && !sort.startsWith("-");
    const sortName = isAscending ? sort : sort?.slice(1) || "";

    setActiveSelectedValue({
      name: sortName,
      value: isAscending || false,
    });
    setSortNumber(!!sort);
  }, [sort]);

  return (
    <Wrapper>
      <WrapperDrawer>
        <Drawer
          icon="sort"
          label={`Sort ${sortNumber ? "(1)" : ""}`}
          content={
            <WrapperSort>
              <H3>Sort by</H3>
              <LineSpacer />
              {sortOptions.map(({ label, name, options, testId }: any, i) => (
                <RadioWrapper
                  selectedValue={selectedValue}
                  className="ạhkfhsd"
                  key={i}
                >
                  <Radio
                    label={label}
                    name={name}
                    options={options}
                    sendValue={({ value }: any) => {
                      setSelectedValue({ name, value } as any);
                    }}
                    testId={testId}
                  />
                  <Line />
                </RadioWrapper>
              ))}
            </WrapperSort>
          }
          textSave="Apply"
          textCancel="Clear All"
          closeOnCancel={false}
          onCancel={() => {
            setSelectedValue({
              name: "",
              value: false,
            });
          }}
          onOpen={() => setIsShowDrawer(true)}
          onClose={() => setIsShowDrawer(false)}
          onSave={async () => {
            if (selectedValue.name) {
              setSort(
                selectedValue.value
                  ? selectedValue.name
                  : `-${selectedValue.name}`,
              );
              setSortNumber(true);
              setActiveSelectedValue(selectedValue);
            } else {
              setSort(undefined);
              setSortNumber(false);
            }
          }}
        />
      </WrapperDrawer>
    </Wrapper>
  );
};
