import get from "lodash/get";
import { API_URL } from "Config";
import { getJSON, generateErrorHandler } from "Utils/Network";
import { TEMPLATE_SEARCH } from "Constants/actionTypes";

const handleError = generateErrorHandler(TEMPLATE_SEARCH.ERROR);

export function templatesRestore(params: {[key: string]: any}) {
  return async (dispatch: (...args: any) => any) => {
    dispatch({ type: TEMPLATE_SEARCH.PENDING, status: true });
    try {
      const {
        query,
        number,
        sort,
      } = params;
      const sp = ["page[size]=25&type=offsite,key-nest,convenience-store"];
      if (query) sp.push(`search=${query}`);
      if (number) sp.push(`page[number]=${number}`);
      if (sort) sp.push(`sort=${sort}`);

      const url = `${API_URL}v3/key-stores?${sp.join("&")}`;
      const json = await getJSON(url);
      const { data } = json;
      const templates = data?.map((item: {[key: string]: any}) => {
        const { id, attributes } = item;
        return {
          ...attributes,
          id,
        };
      });
      dispatch({
        type: TEMPLATE_SEARCH.SUCCESS,
        response: templates,
        totalPages: get(json, "meta.totalPages", 0),
        totalCount: get(json, "meta.totalCount", 0),
      });
    } catch (err: any) {
      // eslint-disable-next-line
      console.warn("templatesRestore err", err);
      dispatch(handleError(err));
    }
  };
}
