import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";

import close from "./assets/cross.svg";
import Icon from "Components/Icon";
import Logout from "./assets/logout.svg";
import { getIconURL } from "./DesktopMenu";

const MenuItem = styled.button<any>`
  background: none;
  border: none;
  color: ${({ theme }) => theme.charcoal};
  cursor: pointer;
  display: block;
  font-family: InterMedium;
  font-size: 18px;
  line-height: 22px;
  outline: none;
  padding: 15px 24px;
  text-align: left;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
  width: 100%;
  ${({ selected }) =>
    selected &&
    css`
      //  background: ${({ theme }) => theme.background.subtle3};
    `}
`;

const Wrapper = styled.div<any>`
  background-color: ${({ theme }) => theme.white};
  width: ${({ show }) => (show ? `79.5%` : "0")};
  height: 100dvh;
  transition: width 0.5s ease-in-out;
  z-index: 99;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0px;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.08);
  ${MenuItem} {
    opacity: ${({ show }) => (show ? 1 : 0)};
    border-right: 2px solid ${({ theme }) => theme.border.default};
  }

  @media (min-width: ${({ theme }) => theme.minTablet}) {
    display: none;
  }
  h6:first-of-type {
    display: block !important;
    padding-left: 32px;
    @media (max-width: ${({ theme }) => theme.maxMobile}) {
      display: none;
    }
  }
`;

const BottomWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 24px;
  border-top: 2px solid ${({ theme }) => theme.border.default};
  padding-top: 24px;
  a {
    img {
      margin-right: 16px;
    }
  }
`;
const Text = styled.span<any>`
  color: ${({ theme }) => theme.text.subtle};
  margin-left: 16px;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  vertical-align: top;
  display: inline-block;
`;
const TextDark = styled(Text)`
  color: ${({ theme }) => theme.charcoal};
  font-family: InterMedium;
  margin-left: 24px;
`;
const Close = styled.div`
  display: flex;
  margin: 24px 16px 18px 16px;
  border-bottom: 2px solid ${({ theme }) => theme.border.default};
  padding-bottom: 18px;
  img {
    width: 16px;
    height: 16px;
    margin-left: auto;
  }
`;
const Scrollable = styled.div`
  height: calc(100% - 86px);
  overflow-y: auto;
`;

interface IProps extends RouteComponentProps {
  handleClose: (...args: any) => any;
  items: any[];
  visible?: boolean;
}
const MobileMenu: React.FC<IProps> = ({
  handleClose,
  history,
  items,
  location,
  visible,
}) => {
  const { pathname } = location;
  return (
    <Wrapper show={visible}>
      <Scrollable>
        <Close
          onClick={() => {
            handleClose();
          }}
        >
          <img src={close} alt="close" />
        </Close>
        {items
          .filter((item) => !item.mobileOnly)
          .map(({ label, link }) => {
            if (link.startsWith("http")) {
              return (
                <Fragment key={label}>
                  <MenuItem
                    selected={pathname !== "/" && pathname.startsWith(link)}
                    onClick={() => {
                      handleClose();
                      window.open(link);
                    }}
                    key={`menu${label}`}
                  >
                    <Icon
                      type={getIconURL.get(label.toLowerCase()) as any}
                      size={24}
                      fill="#939296"
                    />
                    <Text>{label}</Text>
                  </MenuItem>
                </Fragment>
              );
            }
            return (
              <MenuItem
                selected={pathname !== "/" && pathname.startsWith(link)}
                onClick={() => {
                  handleClose();
                  history?.push(link);
                }}
                key={`menu${label}`}
              >
                <Icon
                  type={getIconURL.get(label.toLowerCase()) as any}
                  size={24}
                  fill="#939296"
                />
                <Text>{label}</Text>
              </MenuItem>
            );
          })}
      </Scrollable>
      <BottomWrapper>
        <Link to="/logout">
          <TextDark>
            <img src={Logout} alt="logout" /> Log Out
          </TextDark>
        </Link>
        {/* {items
          .filter((item) => item.mobileOnly)
          .map(({ label, link }, index) => {
            return (
              <Fragment key={label}>
                <MenuItem
                  selected={pathname !== "/" && pathname.startsWith(link)}
                  onClick={() => {
                    handleClose();
                    window.open(link);
                  }}
                  key={`menu${label}`}
                >
                  {label}
                </MenuItem>
              </Fragment>
            );
          })} */}
      </BottomWrapper>
    </Wrapper>
  );
};

export default withRouter(MobileMenu as any);
