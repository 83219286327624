import Icon, {
    ICON_BANK,
    ICON_BOOK,
    ICON_CALENDAR,
    ICON_CHANNEL,
    ICON_DEVTOOL,
    ICON_FAQ,
    ICON_HOUSE,
    ICON_KEY,
    ICON_KNOWLEDGE,
    ICON_OPENTASK,
    ICON_RESOURCES,
    ICON_ROCKET,
    ICON_STATEMENT,
    ICON_SUITCASE,
    ICON_TEMPLATE,
    ICON_USERS,
    SVG,
    IconName,
  } from "./Icon";
  const iconTypes = {
    ICON_BANK,
    ICON_BOOK,
    ICON_CALENDAR,
    ICON_CHANNEL,
    ICON_DEVTOOL,
    ICON_FAQ,
    ICON_HOUSE,
    ICON_KEY,
    ICON_KNOWLEDGE,
    ICON_OPENTASK,
    ICON_RESOURCES,
    ICON_ROCKET,
    ICON_STATEMENT,
    ICON_SUITCASE,
    ICON_TEMPLATE,
    ICON_USERS,
  };
export { iconTypes, SVG };
    export type { IconName };
  export default Icon;
