import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";

import {
  Button,
  BUTTON_INLINE,
  BUTTON_PRIMARY,
  InputText,
  Table,
  TYPE_TEXT,
} from "@madecomfy/webooi";

import BreadCrumb from "Components/BreadCrumb/BreadCrumb";
import ButtonEdit from "Components/Buttons/ButtonEdit";
import Loading from "Components/Loading";
import Photo from "./assets/photo.svg";
import TemplatePopup from "./TemplatePopup";
import TemplateDeletePopup from "./TemplateDeletePopup";

import { Body } from "Components/Typography";
import { pluralise, titleCase } from "Utils";

const Container = styled.div`
  position: relative;
`;
const ButtonContainer = styled.div`
  position: relative;
  opacity: 0;
  background: ${({ theme }) => theme.background.subtle3};
  padding: 0 8px;
  border-radius: 4px;
  svg {
    fill: #303336 !important;
    margin-right: 0 !important;
  }
`;
const Element = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    margin: 0;
    line-height: 24px;
    cursor: pointer;
  }
`;

const Text = styled.p`
  margin: 0;
  line-height: 24px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  padding: 24px;
`;

const Header = styled.p`
  font-family: InterBold;
  font-size: 28px;
  font-style: normal;
  line-height: 40px;
  color: ${({ theme }) => theme.text.default};
  margin-top: 0;
  margin-bottom: 32px;
`;
const ToolbarWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
`;
const InputWrapper = styled.div`
  position: relative;
  width: calc(100% - 200px);
  input {
    height: 40px;
    padding: 0 8px;
    max-width: 450px;
  }
`;
const TableWrapper = styled.div`
  overflow: visible;
  margin-top: 24px;
  padding: 16px;
  background-color: ${({ theme }) => theme.background.white};
  border-radius: 8px;
  table {
    tbody {
      tr {
        &:hover {
          background: ${({ theme }) => theme.background.accentGreenSubtle};
          div[data-test="delete"],
          div[data-test="edit"] {
            opacity: 1;
          }
        }
      }
    }
    td {
      min-width: auto;
      padding: 8px 16px;
      height: 48px;
    }
    th {
      padding: 0 16px 16px;
    }
  }
  > div {
    p {
      font-size: 16px;
    }
  }
`;

const NoDataWrapper = styled.div`
  margin-top: 16px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Type = styled.p`
  font-size: 12px;
  font-family: "InterMedium";
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  background: ${({ theme }) => theme.background.accentGreenSubtle};
  border-radius: 24px;
  width: max-content;
`;
const Address = styled.p`
  color: ${({ theme }) => theme.text.subtle};
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
`;
const Photos = styled.p`
  font-size: 12px;
  font-family: "InterSemiBold";
  display: flex;
  width: max-content;
  gap: 4px;
  background: ${({ theme }) => theme.background.subtle2};
  border-radius: 4px;
  padding: 4px 8px;
  align-items: center;
`;

interface IProps {
  templates: { [key: string]: any };
  templatesRestore: Function;
}
const Templates: React.FC<IProps> = (props) => {
  const { templates, templatesRestore } = props;
  const { pending } = templates;
  const [data, setData] = useState({ type: "", data: {} });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [reset, setReset] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPopup, setShowPopup] = useState({ show: false, type: "" });
  const [showDeletePopup, setShowDeletePopup] = useState("");
  const [tableSort, setTableSort] = useState<{
    column: string;
    order: "asc" | "desc";
  }>({
    column: "name",
    order: "asc",
  });

  const loader = useRef(null);

  // handle sort
  const templatesSort = new Map([
    ["name", "name"],
    ["storeType", "type"],
    ["address", "address"],
    ["photos", "photos"],
    ["numberOfProperties", "properties"],
  ]);

  const getSortVal = (columnItem: string, order: "asc" | "desc") => {
    const sortValue = templatesSort.get(columnItem);
    return sortValue ? `${order === "desc" ? "-" : ""}${sortValue}` : "";
  };

  const fetchData = async (pageNumber: number, val?: string, sort?: string) => {
    const params = {
      query: val,
      number: pageNumber,
      sort,
    };
    setLoading(true);
    try {
      await templatesRestore(params);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
    debounce((num, searchTerm, sort) => {
      fetchData(num, searchTerm, sort);
    }, 400),
    [],
  );

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !loading && items.length >= 25) {
        setReset(false);
        setIsLoadingMore(true);
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        if (nextPage > templates.totalPages) {
          setIsLoadingMore(false);
          return;
        }

        debouncedFetchData(
          nextPage,
          searchTerm,
          getSortVal(tableSort.column, tableSort.order),
        );
      }
    },
    [loading],
  );

  useEffect(() => {
    setReset(true);
    setPageNumber(1);
    debouncedFetchData(
      1,
      searchTerm,
      getSortVal(tableSort.column, tableSort.order),
    );
  }, [searchTerm, tableSort.column, tableSort.order]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    setItems((prevItems: any) =>
      reset
        ? templates?.templates
        : ([...prevItems, ...templates?.templates] as any),
    );
    setIsLoadingMore(false);
  }, [templates?.templates]);

  const columns = [
    {
      title: "Template name",
      dataIndex: [
        "name",
        "displayName",
        "storeType",
        "address",
        "openingTime",
        "photos",
        "longDescription",
        "keyNestId",
      ],
      render: (name: string, rowId: string) => {
        return (
          <Container
            onClick={() => {
              setShowPopup({ show: true, type: "edit" });
              setData({
                type: "edit",
                data: {
                  displayName: name[1],
                  name: name[0],
                  storeType: name[2],
                  address: name[3],
                  openingTime: name[4],
                  photos: name[5],
                  longDescription: name[6],
                  keyNestId: name[7],
                  storeId: rowId,
                },
              });
            }}
          >
            <Text>{name[0]}</Text>
          </Container>
        );
      },
      sortable: true,
    },
    {
      title: "Type",
      dataIndex: [
        "storeType",
        "displayName",
        "name",
        "address",
        "openingTime",
        "photos",
        "longDescription",
        "keyNestId",
      ],
      render: (type: string, rowId: string) => {
        return (
          <Container
            onClick={() => {
              setShowPopup({ show: true, type: "edit" });
              setData({
                type: "edit",
                data: {
                  displayName: type[1],
                  name: type[2],
                  storeType: type[0],
                  address: type[3],
                  openingTime: type[4],
                  photos: type[5],
                  longDescription: type[6],
                  keyNestId: type[7],
                  storeId: rowId,
                },
              });
            }}
          >
            <Element>
              <Type>{titleCase(type[0])}</Type>
            </Element>
          </Container>
        );
      },
      sortable: true,
    },
    {
      title: "Address",
      dataIndex: [
        "address",
        "displayName",
        "name",
        "storeType",
        "openingTime",
        "photos",
        "longDescription",
        "keyNestId",
      ],
      render: (address: string, rowId: string) => {
        return (
          <Container
            onClick={() => {
              setShowPopup({ show: true, type: "edit" });
              setData({
                type: "edit",
                data: {
                  displayName: address[1],
                  name: address[2],
                  storeType: address[3],
                  address: address[0],
                  openingTime: address[4],
                  photos: address[5],
                  longDescription: address[6],
                  keyNestId: address[7],
                  storeId: rowId,
                },
              });
            }}
          >
            <Element>
              <Address title={address[0]}>{address[0]}</Address>
            </Element>
          </Container>
        );
      },
      sortable: true,
    },
    {
      title: "Photos",
      dataIndex: "photos",
      render: (photos: string, rowId: string) => {
        return (
          <Container>
            <Element>
              {photos?.length > 0 ? (
                <Photos>
                  <img src={Photo} alt="photos" />
                  {pluralise(photos?.length, "Photo", "Photos")}
                </Photos>
              ) : null}
            </Element>
          </Container>
        );
      },
      sortable: true,
    },
    {
      title: "Properties",
      dataIndex: "numberOfProperties",
      alignCol: "right",
      render: (numberOfProperties: string, rowId: string) => {
        return (
          <Container>
            <Element>{numberOfProperties}</Element>
          </Container>
        );
      },
      sortable: true,
    },
    {
      title: "",
      dataIndex: "keyNestId",
      alignCol: "right",
      render: (keyNestId: string, rowId: string) => {
        return (
          <ButtonContainer data-test="delete">
            <Element>
              <ButtonEdit
                label=""
                icon="bin"
                onClick={() => setShowDeletePopup(rowId)}
              />
            </Element>
          </ButtonContainer>
        );
      },
    },
    {
      title: "",
      dataIndex: [
        "displayName",
        "name",
        "storeType",
        "address",
        "openingTime",
        "photos",
        "longDescription",
        "keyNestId",
      ],
      render: (data: any[], rowId: string) => {
        return (
          <ButtonContainer data-test="edit">
            <Element>
              <ButtonEdit
                label=""
                onClick={() => {
                  setShowPopup({ show: true, type: "edit" });
                  setData({
                    type: "edit",
                    data: {
                      displayName: data[0],
                      name: data[1],
                      storeType: data[2],
                      address: data[3],
                      openingTime: data[4],
                      photos: data[5],
                      longDescription: data[6],
                      keyNestId: data[7],
                      storeId: rowId,
                    },
                  });
                }}
              />
            </Element>
          </ButtonContainer>
        );
      },
    },
  ];
  return (
    <Wrapper>
      <BreadCrumb
        items={[
          {
            label: "Key Collection Templates",
            staticLabel: "MadeComfy / ",
          },
        ]}
        staticText={""}
      />
      <ToolbarWrapper>
        <Header>Key Collection Templates</Header>
        <Button
          label="Add New Template"
          icon="plus"
          my={0}
          onClick={() => {
            setShowPopup({ show: true, type: "add" });
            setData({ type: "add", data: {} });
          }}
          styling={BUTTON_INLINE}
          testId="add new template"
          type={BUTTON_PRIMARY}
        />
      </ToolbarWrapper>
      <ToolbarWrapper>
        <InputWrapper>
          <InputText
            name="searchTerm"
            placeholder="Search for templates"
            testId="template-search"
            type={TYPE_TEXT}
            my={0}
            sendValue={({ value }: any) => {
              setSearchTerm(value?.trim());
            }}
          />
        </InputWrapper>
      </ToolbarWrapper>
      {pending && <Loading task="statements" />}
      <TableWrapper>
        <Table
          columns={columns}
          data={items}
          pagination={false}
          showSearch={false}
          sortBy={tableSort.column}
          sortOrder={tableSort.order}
          rowsPerPage={1e6}
          onTableSortBy={(columnItem, order) => {
            setTableSort({ ...tableSort, column: columnItem, order });
          }}
          sortLocal={false}
        />
        {templates?.templates?.length === 0 && (
          <NoDataWrapper>
            <Body>
              {searchTerm
                ? "There are no templates matching your search"
                : "There are no templates available yet"}
            </Body>
          </NoDataWrapper>
        )}
      </TableWrapper>
      <div
        ref={loader}
        style={{ height: "10px", background: "transparent" }}
      ></div>
      {isLoadingMore && <Loading task="load more templates" />}
      {showPopup?.show && (
        <TemplatePopup
          storeData={data}
          setReset={setReset}
          setShowPopup={setShowPopup}
          showPopup={showPopup}
          templatesRestore={templatesRestore}
        />
      )}
      {showDeletePopup && (
        <TemplateDeletePopup
          setShowDeletePopup={setShowDeletePopup}
          storeId={showDeletePopup}
          templatesRestore={templatesRestore}
        />
      )}
    </Wrapper>
  );
};

export default Templates;
