// TODO remove this in favour of webooi now that updates work

import styled, { css } from "styled-components";

export const InputText = styled.input<any>`
  background: ${({ isEnabled, theme }) =>
    isEnabled
      ? theme.inputText.background
      : theme.inputText.disabled.background};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.charcoalLight50};
  box-sizing: border-box;
  color: ${({ theme }) => theme.charcoal};
  font-family: Inter;
  font-size: 16px;
  outline: none;
  width: 100%;
  transition: border 0.15s linear, box-shadow 0.3s linear;
  -webkit-appearance: none;
  height: 48px;
  padding: 12px 16px;
  margin: 0;
  :focus {
    box-shadow: ${({ theme }) => theme.button.focus};
  }
  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.border.alert};
    `};
`;

export const Label = styled.label`
  font-size: 16px;
`;

export const Wrapper = styled.div`
  div {
    margin: -16px 0px 16px;
  }
`;
