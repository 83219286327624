import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import Bold from "./assets/bold.svg";
import Italic from "./assets/italic.svg";
import List from "./assets/list.svg";
import Underline from "./assets/underline.svg";

const Wrapper = styled.div`
  margin-bottom: 24px;
`;
const Label = styled.label`
  color: ${({ theme }) => theme.text.default};
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;
const Instructions = styled.p`
  color: ${({ theme }) => theme.text.subtle};
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 4px;
`;
const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.border.slateGray};
  border-radius: 4px;
  min-height: 375px;
  padding: 8px;
`;
const Options = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border.slateGray};
  margin-left: -8px;
  margin-right: -8px;
  padding: 0 8px 8px 8px;
  display: flex;
  gap: 8px;
`;
const Image = styled.img`
  cursor: pointer;
`;
const Content = styled.div`
  min-height: 300px;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 8px 0;
`;

interface RichTextEditorProps {
  instructions: string;
  label: string;
  onSave: (htmlContent: string) => void;
  value?: string;
}

const TextEditor: React.FC<RichTextEditorProps> = ({
  instructions,
  label,
  onSave,
  value = "",
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const selectionRef = useRef<Range | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = value;
    }
  }, []);

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      selectionRef.current = selection.getRangeAt(0);
    }
  };

  const restoreSelection = () => {
    const selection = window.getSelection();
    if (selection && selectionRef.current) {
      selection.removeAllRanges();
      selection.addRange(selectionRef.current);
    }
  };

  // Function to apply formatting (Bold, Italic, Underline)
  const toggleFormat = (command: string) => {
    saveSelection();
    document.execCommand(command, false, undefined);
    restoreSelection();
    editorRef.current?.focus();
  };

  // Function to insert an unordered list
  const insertList = () => {
    saveSelection();
    document.execCommand("insertUnorderedList", false, undefined);
    restoreSelection();
    editorRef.current?.focus();
  };

  // Auto-save content while typing
  const handleInput = () => {
    saveSelection();
    if (editorRef.current) {
      onSave(editorRef.current.innerHTML);
    }
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Instructions>{instructions}</Instructions>
      <Container>
        <Options>
          <Image src={Bold} alt="bold" onClick={() => toggleFormat("bold")} />
          <Image
            src={Italic}
            alt="italic"
            onClick={() => toggleFormat("italic")}
          />
          <Image
            src={Underline}
            alt="underline"
            onClick={() => toggleFormat("underline")}
          />
          <Image src={List} alt="list" onClick={insertList} />
        </Options>
        <Content
          data-test="rich-text-editor"
          ref={editorRef}
          contentEditable={true}
          onInput={handleInput}
          suppressContentEditableWarning={true}
          onKeyUp={saveSelection}
          onMouseUp={saveSelection}
        />
      </Container>
    </Wrapper>
  );
};

export default TextEditor;
