import { TEMPLATE_SEARCH } from "Constants/actionTypes";

export function searchTemplates(
  state = {
    error: false,
    errorCode: 0,
    errorMessage: "",
    templates: [],
    pending: false,
    success: false,
    totalCount: 0,
    totalPages: 0,
  },
  action: {[key: string]: any},
) {
  switch (action.type) {
    case TEMPLATE_SEARCH.SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        templates: action.response,
        totalCount: action.totalCount,
        totalPages: action.totalPages,
      };
    case TEMPLATE_SEARCH.PENDING:
      return {
        ...state,
        pending: action.status,
        totalCount: 0,
        totalPages: 0,
      };
    case TEMPLATE_SEARCH.ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        errorCode: action.errorCode,
        pending: false,
      };
    default:
      return state;
  }
}
