const scriptUrl =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyBo00hBWw-wB17EwD2KdNOjALfkFeSLBfs&language=en&libraries=geometry,drawing,places";

// HOF to ensure Google Maps API is loaded
const withGoogleFunction = <T extends (...args: any[]) => any>(fn: T) => {
  let isLoaded = false;

  // Load the script only once
  const loadGoogleMaps = () => {
    return new Promise<void>((resolve, reject) => {
      if (window.google && window.google.maps) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = scriptUrl;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        isLoaded = true;
        resolve();
      };

      script.onerror = () => reject(new Error("Failed to load Google Maps"));

      document.body.appendChild(script);
    });
  };

  return async (...args: Parameters<T>) => {
    if (!isLoaded) {
      try {
        await loadGoogleMaps(); // Load the API if not already loaded
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error loading Google Maps:", error);
        return;
      }
    }

    // Execute the wrapped function after ensuring the API is loaded
    return fn(...args);
  };
};

export default withGoogleFunction;
