import styled from "styled-components";

import binIcon from "./assets/bin.svg";

export const WrapperUpload = styled.div<any>`
  margin-top: 4px;
  margin-bottom: 16px;
  .rug .rug-handle {
    display: flex;
    flex-direction: row;
    height: auto;
    gap: 16px;
    padding: 24px;
    background-color: ${({ theme }) => theme.background.subtle2};
    &::before {
      width: 100%;
      height: 100%;
      border: 2px dashed ${({ theme }) => theme.charcoalLight50};
    }
  }
  .rug .rug-handle svg.rug-handle-icon {
    width: 24px;
    min-width: 24px;
    height: 30px;
    background: transparent;
    color: ${({ theme }) => theme.icon.default};
  }
  .rug .rug-handle svg.rug-handle-icon polyline,
  .rug .rug-handle svg.rug-handle-icon line {
    color: ${({ theme }) => theme.icon.default};
  }
  .rug .rug-handle .rug-handle-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .rug .rug-handle .rug-handle-info span {
    display: none;
  }
  .rug .rug-handle .rug-handle-info .rug-handle-button {
    background-color: ${({ theme }) => theme.background.subtle3};
    border-radius: 4px;
    margin: 0;
    max-width: min-content;
    max-height: 40px;
    overflow: hidden;
    visibility: hidden;
    position: relative;
    padding: 8px 32px;
    &::after {
      content:'Upload'; 
      visibility: visible;
      display: block;
      position: absolute;
      border-radius: 4px;
      border: 1.5px solid ${({ theme }) => theme.border.transparent};
      color: ${({ theme }) => theme.text.default};
      background-color: ${({ theme }) => theme.background.subtle3};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 8px 32px;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      text-transform: capitalize;
    }
  }
  .rug .rug-handle .rug-handle-info .rug-handle-button:hover {
    &::after {
      color: ${({ theme }) => theme.text.default};
      background-color: ${({ theme }) => theme.background.subtle3Hover};
    }
  }
  .rug .rug-handle .rug-handle-info .rug-handle-drop-text {
    font-size: 16px;
    text-align: left;
    line-height: 22px;
    color: ${({ theme }) => theme.charcoal};
  }
  .rug-handle-drop-text {
    text-transform: lowercase;
  }
  .rug-handle-drop-text::first-letter {
    text-transform: uppercase;
  }
  .rug-card {
    width: 100%;
    height: 242px;
    border-radius: 0;
    margin: 0;
  }
  .rug-card.__error {
    border: none;
  }
  .rug-card .rug-card-refresh,
  .rug-card .rug-card-progress,
  .rug-card .rug-card-progress-count,
  .rug-card .rug-card-upload-button {
    display: none;
  }
  .rug-card .rug-card-image {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  div[type="info"] {
    border-radius: 8px;
  }
`;
export const UploadCard = styled.div<any>`
  margin-top: 16px;
  & > div {
    display: grid;
    grid-template-columns: ${({ view }) =>
      view === "grid" ? "repeat(2, 48%)" : "repeat(5, 18%)"};
    grid-gap: ${({ view }) => (view === "grid" ? "24px" : "16px")};
    & > div:last-child,
    & > div:nth-last-child(2) {
      #select-container {
        bottom: 100%;
        top: auto;
      }
    }
    @media (max-width: ${({ theme }) => theme.maxMobile}) {
      grid-template-columns: none;
    }
  }
`;

export const ListWrap = styled.div`
  .rug-list {
    border-radius: 0px;
    margin-bottom: 0px;
  }
  .rug-list .rug-list-image {
    width: 100%;
    height: 94px;
    padding: 0;
    cursor: grab;
    @media (max-width: ${({ theme }) => theme.maxMobile}) {
      height: auto;
    }
  }
  .rug-list .rug-list-image img {
    object-fit: cover;
  }
  .rug-list .rug-list-content,
  .rug-list-upload-button {
    display: none;
  }
  .rug-list .rug-list-remove {
    top: 4px;
    right: 4px;
    background: ${({ theme }) => theme.white};
    border-radius: 4px;
    opacity: 1;
    color: ${({ theme }) => theme.charcoal};
    cursor: pointer;
    svg {
      display: none;
    }
    &:before {
      display: inline-flex;
      content: '';
      background-image: url(${binIcon});
      background-size: 16px 16px;
      height: 16px;
      width: 16px;
    }
  }
  .rug-list .rug-list-remove:hover {
    &:before {
      opacity: 0.65;
    }
  }
`;
