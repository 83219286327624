// renders a modal popup either centrally placed for desktop or full screen if on mobile.
// note `PopupLoading` and `PopupPortal` below

import React, { PureComponent } from "react";
import styled, { css } from "styled-components";

import Loading from "Components/Loading";

import crossIcon from "./assets/crossIcon.svg";
import PopupPortal from "./PopupPortal";

interface IProps {
  body: { [key: string]: any };
  footer?: { [key: string]: any };
  handleClose: (...args: any) => any;
  height?: number;
  width: number;
  mobileWidth?: number;
}

const ButtonClosePosition = styled.div`
  position: absolute;
  right: 20px;
  top: 24px;
  z-index: 2;
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    display: flex;
    justify-content: end;
    padding: 8px 8px 0 0;
    position: static;
  }
`;

const CloseIcon = styled.span<any>`
  cursor: pointer;
  img {
    transition: transform 0.15s ease-in-out;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
`;

const BlockOut = styled.div`
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
`;

const CardPosition = styled.div<{
  centerDynamicHeight?: boolean;
}>`
  height: 0;
  left: 50%;
  position: fixed;
  top: 50%;
  width: 0;
  z-index: 250;
  ${({ centerDynamicHeight }) =>
    centerDynamicHeight &&
    css`
      transform: translateY(-50%);
      height: auto;
    `}
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    left: 0;
    top: 0;
    ${({ centerDynamicHeight }) =>
      centerDynamicHeight &&
      css`
        //  top: 50%;
        transform: none;
      `}
  }
`;

const LoaderPosition = styled.div`
  left: 50%;
  position: fixed;
  top: calc(50% - 49px);
  z-index: 250;
`;

const Inner = styled.div`
  @keyframes zoomin {
    from {
      transform: scale(0.5) translateY(150px);
      opacity: 0;
    }
    to {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-name: zoomin;
  animation-timing-function: ease-out;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 20px 40px rgba(53, 55, 60, 0.2);
  opacity: 0;
  position: relative;
  transform: scale(0.5) translateY(150px);

  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    border-radius: 4px;
  }
`;

const PopupBody = styled.div`
  overflow-y: auto;
  position: relative;
  max-height: 95vh;
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    max-height: 100dvh;
    height: 100dvh !important;
  }
`;

const PopupFooter = styled.div`
  padding: 0 24px;
`;
const Container = styled.div`
  @media (max-width: ${({ theme }) => theme.maxMobile}) {
    height: calc(100vh - 96px);
    overflow: auto;
  }
`;

// prevent iOS scrolling body. from https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
const freezeVp = (e: Event) => e.preventDefault();

const FOOTER_HEIGHT = 150; // no doubt this will vary one day.
// needs to be extra large to deal with mobile nav bar.

export const ButtonClose = ({
  id,
  handleClick,
}: {
  id?: any;
  handleClick: (...args: any) => any;
}) => {
  const props = id ? { id } : {};
  return (
    <CloseIcon {...props} onClick={handleClick}>
      <img src={crossIcon} alt="close icon" />
    </CloseIcon>
  );
};

class Popup extends PureComponent<IProps> {
  state = {
    windowHeight: 0,
    windowWidth: 0,
  };

  isComponentMounted = false;

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("resize", this.resize);
    document.body.addEventListener("touchmove", freezeVp, false);
    this.resize();
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("resize", this.resize);
    document.body.removeEventListener("touchmove", freezeVp, false);
    document.body.style.overflow = "inherit";
  }

  resize = () => {
    if (!this.isComponentMounted) return;
    const { innerWidth, innerHeight } = window;
    // document.body.height = innerHeight;
    this.setState({ windowWidth: innerWidth, windowHeight: innerHeight });
  };

  render() {
    const {
      body,
      footer,
      handleClose,
      height = 0,
      width,
      mobileWidth,
    } = this.props;

    const { windowHeight, windowWidth } = this.state;
    const displayHeight = Math.min(height, windowHeight);
    const displayWidth = Math.min(width, windowWidth);
    const isMobileFullScreen = windowWidth <= 600;

    const innerStyle = isMobileFullScreen
      ? {
          height: !height ? "auto" : `${windowHeight}px`,
          left: `${(windowWidth - (mobileWidth || windowWidth)) / 2}px`,
          top: 0,
          width: `${mobileWidth || windowWidth}px`,
        }
      : {
          height: !height ? "auto" : `${displayHeight}px`,
          left: `${-displayWidth / 2}px`,
          top: `${-displayHeight / 2}px`,
          width: `${displayWidth}px`,
        };
    const bodyStyle = isMobileFullScreen
      ? {
          height: !height
            ? "auto"
            : footer
            ? `${windowHeight - FOOTER_HEIGHT}px`
            : `${windowHeight}px`,
        }
      : {
          height: !height
            ? "auto"
            : footer
            ? `${displayHeight - FOOTER_HEIGHT}px`
            : `${displayHeight}px`, // there used to be + 20 px here. added in MAD-2223 to fix a scrollbar it seems
        };

    return (
      <PopupPortal>
        <BlockOut id="popup-blockout" onClick={handleClose} />
        <CardPosition id="popup-position" centerDynamicHeight={!height}>
          <Inner id="popup" style={innerStyle}>
            <PopupBody id="popup-body" style={bodyStyle}>
              <ButtonClosePosition>
                <ButtonClose id="popup-close-cross" handleClick={handleClose} />
              </ButtonClosePosition>
              <Container>
                <>{body}</>
              </Container>
            </PopupBody>
            {footer && (
              <PopupFooter id="popup-footer">
                <>{footer}</>
              </PopupFooter>
            )}
          </Inner>
        </CardPosition>
      </PopupPortal>
    );
  }
}

// a good solution for a Popup with pending content - since who knows how big ther Popup should be?
// rather than render the Popup whilst content is loading, render this first, and then render
// the complete Popup once loaded.
const PopupLoading = () => (
  <PopupPortal>
    <BlockOut id="popup-blockout" />
    <LoaderPosition id="popup-position">
      <Loading task="updatingPopupLoading" />
    </LoaderPosition>
  </PopupPortal>
);

export default Popup;
export { PopupLoading };
