// asyncActionType is to be deprecated, use asyncAction - no need for CONNECTION_FAILURE
const asyncActionType = (type: string) => ({
  ...asyncAction(type),
  CONNECTION_FAILURE: `${type}_CONNECTION_FAILURE`,
});

const asyncAction = (type: string) => ({
  ERROR: `${type}_ERROR`,
  PENDING: `${type}_PENDING`,
  RESET: `${type}_RESET`,
  SUCCESS: `${type}_SUCCESS`,
});

// throughout the app, dismiss any error. each reducer has to be configured to accept it.
export const DISMISS_ERRORS = "DISMISS_ERRORS";

// auth
export const FORGOT_PASSWORD = asyncActionType("FORGOT_PASSWORD");
export const CREATE_PASSWORD = asyncActionType("CREATE_PASSWORD");
export const SESSION = asyncAction("SESSION");
export const VERIFY_TOKEN = asyncActionType("VERIFY_TOKEN");

// events
export const EVENT_SEARCH = asyncAction("EVENT_SEARCH");
export const RESERVATION_SEARCH = asyncAction("RESERVATION_SEARCH");

// users
export const EDIT_USER = asyncAction("EDIT_USER");
export const EDIT_USER_PERMISSIONS = asyncAction("EDIT_USER_PERMISSIONS");
export const USER_HISTORY = asyncAction("USER_HISTORY");

// properties
export const PROPERTY_REVENUE = asyncAction("PROPERTY_REVENUE");
export const CALENDAR_BLOCK = asyncActionType("CALENDAR_BLOCK");
export const SEARCH_PROPERTY_QUERY = asyncActionType("SEARCH_PROPERTY_QUERY");
export const PROPERTY_PERFORMANCE = asyncActionType("PROPERTY_PERFORMANCE");

// single booking
export const BOOKING = asyncAction("BOOKING");
export const BOOKING_VERIFICATION = asyncAction("BOOKING_VERIFICATION");
export const BOOKING_QUOTES = asyncAction("BOOKING_QUOTES");

// range of bookings
export const BOOKINGS_PROPERTY = asyncAction("BOOKINGS_PROPERTY");

// single property
export const CURRENT_PROPERTY = asyncActionType("CURRENT_PROPERTY");

// channels
export const CHANNEL_DATA = asyncAction("CHANNEL_DATA");
export const CHANNEL_ACCOUNT = asyncAction("CHANNEL_ACCOUNT");
export const CHANNEL_LISTING = asyncAction("CHANNEL_LISTING");

// resources
export const NEWSLETTER_DATA = asyncAction("NEWSLETTER_DATA");

// statements
export const STATEMENTS_DATA = asyncAction("STATEMENTS_DATA");

// templates
export const TEMPLATE_SEARCH = asyncAction("TEMPLATE_SEARCH");
